<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <staffing v-show="id == 1 || id == 10 || id == 11 || id == 12 || id == 14"></staffing>
        <expenseType v-show="id == 2 || id == 3 || id == 4 || id == 9"></expenseType>
        <specialPersonnel v-show="id == 6"></specialPersonnel>
        <dataPanel v-show="id == 13"></dataPanel>
        <reimbursementType v-show="id == 5"></reimbursementType>
        <!-- <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getconfigDetailList">
                    <div class="listCell" v-for="item in list" :key="item.id" @mouseup="up($event)"
                        @mousedown="down($event)" @click="goDetails(item.id)">
                        <van-swipe-cell :disabled="powerList == ''">
                            <div class="cellName">{{ item.name }}</div>
                            <template #right>
                                <van-button type="danger" native-type="button"
                                    @click="deleteSupplier(item.id)">删除供应商</van-button>
                            </template>
                        </van-swipe-cell>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div> -->
    </div>
</template>
    
<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Search,
    Toast,
    List,
    PullRefresh,
    Cell,
    SwipeCell,
    Button,
    Dialog,
    Radio,
    RadioGroup,
    Form,
    Popup,
    Field
} from "vant";

import { configDetailList, configDetailDeltel, configDetailAdd } from "../api/configDetail";

import { getToken } from "../units/auth";

import staffing from '../components/configDetail/staffing.vue'
import expenseType from '../components/configDetail/expenseType.vue'
import specialPersonnel from "../components/configDetail/specialPersonnel.vue"
import dataPanel from '../components/configDetail/dataPanel.vue'
import reimbursementType from '../components/configDetail/reimbursementType.vue'


Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Field)
export default {
    name: "configDetailList",
    components: {
        headTitle,
        staffing,
        expenseType,
        specialPersonnel,
        dataPanel,
        reimbursementType
    },
    data() {
        return {
            title: "",
            value: "",
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            pageX: 0,
            pageY: 0,
            isDrag: false,
            id: null,
            addFrom: {
                name: '',
                address: '',
                contacts: '',
                mobile: '',
                bank: '',
                bankNumber: '',
                mainProducts: '',
                businessLicense: 0,
                priceList: 0,
                taxNumber: '',
            },
            powerList: [], //value 1总经理 2财务 3行政
            userId: "",
            isModify: false,
            isLoading: false,
            equipment: false,
            routerPath: '/configList',
        };
    },
    mounted() {
        this.id = this.$route.query.id
        this.title = this.$route.query.title
        this.getUserId();
        this.getPowerList();
        this.isMobile()
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getUserId() {
            this.userId = getToken("userId");
        },
        getconfigDetailList() {
            this.loading = true;
            configDetailList(this.id).then((res) => {
                this.list = res.data.rows
                this.finished = true;
            });
        },

        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1;
            this.list = [];
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getconfigDetailList();
        },
        add() {
            this.$router.push({
                path: "/newPjPayment",
            });
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            configDetailAdd(this.addFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.isModify = false
                    this.onRefresh();
                    this.addFrom = {
                        name: '',
                        address: '',
                        contacts: '',
                        mobile: '',
                        bank: '',
                        bankNumber: '',
                        mainProducts: '',
                        businessLicense: 0,
                        priceList: 0,
                        taxNumber: '',
                    }
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
        //供应商删除
        deleteSupplier(id) {
            Dialog.confirm({
                message: `是否删除该供用商`,
                confirmButtonColor: "#0092fe",
            }).then(() => {
                configDetailDeltel(id).then((res) => {
                    if (res.data.code == 200) {
                        Toast.success("完成操作");
                        this.onRefresh();
                    }
                });
            });
        },
        goDetails(id) {
            if (this.isDrag) {
                return;
            }
            this.$router.push({
                path: "/configDetailDetail",
                query: { id: id },
            });
        },
        up(event) {
            if (this.pageX !== event.pageX) {
                this.isDrag = true;
            } else {
                this.isDrag = false;
            }
        },
        down(event) {
            this.pageX = event.pageX;
            this.pageY = event.pageY;
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    },
};
</script>
    
<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
    position: relative;
}



/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #f6f6f6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.addBtn {
    width: 55px;
    height: 55px;
    position: fixed;
    top: 77%;
    left: 77%;
    cursor: pointer;

    img {
        width: 55px;
        height: 55px;
    }
}

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 55%;
    left: 77.3%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.importBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 66%;
    left: 77.3%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer
}

// /deep/ .van-button {
//     height: 100%;
// }
</style>