<template>
    <div class="list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                finished-text="没有更多了" @load="getconfigDetailList">
                <div class="listCell" v-for="(item, index) in list" :key="item.id" @mouseup="up($event)"
                    @mousedown="down($event)" @click="goDetails(item)">
                    <van-swipe-cell :disabled="powerList == ''">
                        <div class="cellName">第{{ index + 1 }}审核人： {{ item.name }}</div>
                        <div class="cellText">审核标识值（数字越小代表越靠后）： {{ item.value }}</div>
                        <template #right>
                            <!-- <van-button type="info" native-type="button" @click="deleteSupplier(item.id)">修改</van-button> -->
                            <van-button type="danger" native-type="button" @click="deleteSupplier(item.id)">删除</van-button>
                        </template>
                    </van-swipe-cell>
                </div>
            </van-list>
        </van-pull-refresh>
        <van-popup v-model="isShow" closeable style="padding:20px;box-sizing: border-box;" round position="bottom">
            <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
                <van-field readonly clickable name="picker" :value="personnel" label="权限人员" placeholder="点击选择添加权限的人员"
                    @click="showPicker = true" :rules="[{ required: true, message: '权限人员不能为空' }]" required />
                <van-popup v-model="showPicker" position="bottom">
                    <van-picker show-toolbar :columns="allUserList" @confirm="onConfirm" @cancel="showPicker = false" />
                </van-popup>
                <van-field v-model="addFrom.value" label="审核标识值" type="number" placeholder="数字越小代表越靠后审核"
                    :rules="[{ required: true, message: '审核标识值不能为空' }]" required />
                <van-button round block type="info" native-type="submit" size="small" :disabled="isLoading"
                    :loading="isLoading" loading-text="加载中...">提交</van-button>
            </van-form>
        </van-popup>
        <div class="addBtn">
            <img src="../../assets/icon/xz_icon.png" alt="" @click="add" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {
    Toast,
    List,
    PullRefresh,
    Cell,
    Dialog,
    Button,
    Form,
    Popup,
    Field,
    Picker,

} from 'vant'

import { getAllUserList } from '../../api/api'
import { configDetailList, configDetailDeltel, configDetailAdd, configDetailPut } from "../../api/configDetail";
import { getToken } from "../../units/auth";

Vue.use(Toast)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell)
Vue.use(Dialog)
Vue.use(Button)

Vue.use(Popup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Picker)
export default {
    name: 'staffing',
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            pageX: 0,
            pageY: 0,
            isDrag: false,
            id: null,
            addFrom: {
                commonValue: '',//角色的用户id
                configId: '',//配置id
                value: '',//次序 越靠后越后审核
                name: ''// 人名
            },
            powerList: [], //value 1总经理 2财务 3行政
            userId: "",
            isLoading: false,
            isShow: false,
            isAdd: false,
            personnel: '',
            permissions: '',//项目权限人
            permissionList: '',//项目权限列表
            allUserList: [],
            showPicker: false
        }
    },
    mounted() {
        this.addFrom.configId = this.$route.query.id
        this.title = this.$route.query.title
        this.getUserId();
        this.getPowerList();
        this.isMobile()
        this.getUserList()
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getUserId() {
            this.userId = getToken("userId");
        },
        getconfigDetailList() {
            this.loading = true;
            configDetailList(this.addFrom.configId).then((res) => {
                this.list = res.data.rows
                this.finished = true;
                this.refreshing = false
            });
        },

        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.list = [];
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getconfigDetailList();
        },
        add() {
            this.isShow = true
            this.isAdd = true
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            if (this.isAdd) {
                configDetailAdd(this.addFrom).then(res => {
                    if (res.data.code == 200) {
                        this.isLoading = false
                        Toast.success("提交成功");
                        this.onRefresh();
                        this.addFrom.commonValue = ''
                        this.addFrom.value = ''
                        this.personnel = ''
                        this.isShow = false
                    }
                }).catch(() => {
                    this.isLoading = false

                })
            } else {
                configDetailPut(this.addFrom).then(res => {
                    if (res.data.code == 200) {
                        this.isLoading = false
                        Toast.success("修改成功");
                        this.onRefresh();
                        this.addFrom.commonValue = ''
                        this.addFrom.value = ''
                        this.personnel = ''
                        this.isShow = false

                    }
                }).catch(() => {
                    this.isLoading = false

                })
            }

        },
        //删除
        deleteSupplier(id) {
            Dialog.confirm({
                message: `是否删除该成员权限`,
                confirmButtonColor: "#0092fe",
            }).then(() => {
                configDetailDeltel(id).then((res) => {
                    if (res.data.code == 200) {
                        Toast.success("完成操作");
                        this.onRefresh();
                    }
                });
            });
        },
        goDetails(obj) {
            if (this.isDrag) {
                return;
            }
            this.addFrom = JSON.parse(JSON.stringify(obj))
            this.personnel = obj.name
            this.isAdd = false
            this.isShow = true
        },
        up(event) {
            if (this.pageX !== event.pageX) {
                this.isDrag = true;
            } else {
                this.isDrag = false;
            }
        },
        down(event) {
            this.pageX = event.pageX;
            this.pageY = event.pageY;
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        },
        //获取全部人员的信息
        getUserList() {
            getAllUserList().then(res => {
                if (res.data.code == 200) {
                    this.allUserList = res.data.rows.map(it => {
                        return {
                            text: it.name,
                            id: it.userId
                        }
                    })
                }
            })
        },
        onConfirm(value) {
            this.personnel = value.text;
            this.addFrom.commonValue = value.id
            this.addFrom.name = value.text
            this.showPicker = false;
        },
    },
}
</script>

<style lang="less" scoped>
/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #f6f6f6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.addBtn {
    width: 55px;
    height: 55px;
    position: fixed;
    top: 77%;
    left: 77%;
    cursor: pointer;

    img {
        width: 55px;
        height: 55px;
    }
}
</style>